import Login from "./components/Login/Login";
import DashBoard from "./components/Dashboard/Dashboard";
import UserProfile from "./components/UserProfile/UserProfile";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScheduleMessage from "./pages/ScheduleMessage";
import "react-loading-skeleton/dist/skeleton.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/schedule-message" element={<ScheduleMessage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
