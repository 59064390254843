import moment from "moment";
import React from "react";
import { imageServerUrl } from "../../utils/axios-request";

const SingleMsg = ({ userData, item, data, index }) => {
  // console.log({ item });
  const is_agent =
    item.sender_id == data.id || item.sender_id.length < 5 ? 1 : 0;
  // const messageTime = moment(item.createdAt).format("hh:mm A");
  // let messageTime = moment(item.createdAt, "YYYY-MM-DD HH:mm:ss");

  // const timeToAdd = { hours: 5, minutes: 30 };
  // messageTime = messageTime
  //   .add(timeToAdd.hours, "hours")
  //   .add(timeToAdd.minutes, "minutes");

  // const formattedTime = messageTime.format("HH:mm ");
  // console.log("item", item.createdAt);
  // console.log("item", item.createdAt);

  // const difference = now.diff(messageTime, "hours");
  // let dateTime = messageTime.format("HH:mm A");
  // //   if (difference < 24) {
  // //     dateTime =  messageTime.fromNow();
  // //   }

  let msgStyle = { textAlign: "left", width: "100%" };
  if (is_agent) {
    msgStyle = {
      textAlign: "right",
      width: "100%",
    };
  }

  return (
    <div
      className={`${
        parseInt(item.sender_id) !== data.id && item.sender_id.length > 5
          ? "col-start-1 col-end-8"
          : "col-start-6 col-end-13"
      } p-3 rounded-lg`}
      key={index}
    >
      <div
        className={`flex ${
          parseInt(item.sender_id) !== data.id && item.sender_id.length > 5
            ? "flex-row"
            : "flex-row-reverse"
        } items-center`}
      >
        <div
          className={`flex items-center justify-center h-10 w-10 rounded-full flex-shrink-0 ${
            parseInt(item.sender_id) !== data.id && item.sender_id.length > 5
              ? "bg-white"
              : "bg-indigo-500 text-white"
          }`}
        >
          {is_agent
            ? data?.name
                .split(" ")
                .map((part) => part.charAt(0))
                .join("")
            : userData?.name
                .split(" ")
                .map((part) => part.charAt(0))
                .join("")}
          {}{" "}
        </div>
        <div
          style={{ minWidth: "100px", maxWidth: "80%" }}
          className={` ${is_agent ? "mr-4" : ""}`}
        >
          {/* <div className="bg-transparent flex gap-4 items-center  ml-4">
                              <span className="font-semibold text-gray-400 text-sm">
                                {is_agent ? data.name : userData?.name}
                              </span>
                            </div> */}

          <div
            className={`relative ml-3 text-sm ${
              parseInt(item.sender_id) !== data.id && item.sender_id.length > 5
                ? "bg-white"
                : "bg-indigo-500 text-white text-wrap"
            } py-2 shadow rounded-xl`}
          >
            <div className="flex flex-col  items-center px-2 py-1 ">
              <>
                {item?.attachment ? (
                  <a
                    href={`${imageServerUrl}${item.attachment}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={`${imageServerUrl}${item.attachment}`}
                      style={{ height: "100px", width: "150px" }}
                      alt="attachment"
                    />
                  </a>
                ) : (
                  <p
                    className="flex-grow "
                    style={msgStyle}
                    style={{ overflowWrap: "anywhere" }}
                  >
                    {item.message}
                  </p>
                )}

                <p
                  className={`w-full ${is_agent ? "text-right" : "text-left"} `}
                >
                  <span
                    className={`${
                      is_agent ? "text-white" : "text-gray-400"
                    } text-xs`}
                  >
                    {/* {formattedTime} */}
                    {moment(item.createdAt).format("hh:mm A")}
                  </span>
                </p>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
    // {/* </> */}
  );
};

export default SingleMsg;
