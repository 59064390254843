import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import DataTable from "react-data-table-component";
import AddMessageModal from "../components/Modal/AddMessageModal";
import { toast } from "react-toastify";
import { get_api } from "../utils/axios-request";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { MdModeEditOutline } from "react-icons/md";
import UpdateMessageModal from "../components/Modal/UpdateMessageModal";
import { SyncLoader } from "react-spinners";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";

const ScheduleMessage = () => {
  const [addMessageModal, setAddMessageModal] = useState(false);
  const [updateMessageModal, setUpdateMessageModal] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [allScheduledMessages, setAllScheduledMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(() =>
    JSON.parse(localStorage.getItem("data"))
  );

  const getAllLocations = async () => {
    try {
      setLoading(true);
      const response = await get_api(
        `/reps/get-all-locations-by-rep-id/${currentUser?.id}`
      );
      if (response.data.status) {
        setAllLocations(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const getAllScheduledMessages = async () => {
    try {
      setLoading(true);
      const response = await get_api(
        `/scheduled-message/get-all-scheduled-messages-by-user-id/${currentUser?.id}`
      );
      if (response.data.status) {
        setAllScheduledMessages(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (currentUser) {
      getAllLocations();
      getAllScheduledMessages();
    }
  }, [currentUser]);

  const tableColumns = [
    {
      name: "SNo.",
      selector: (row) => row.sno,
      width: "100px",
    },
    {
      name: "Message",
      selector: (row) => row.message,
    },
    {
      name: "Scheduled Time",
      selector: (row) => row.scheduled_time,
    },
    {
      name: "Location",
      selector: (row) => row.location,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    // {
    //   name: "Actions",
    //   selector: (row) => row.actions,
    // },
  ];

  const tableData = allScheduledMessages
    ? allScheduledMessages.map((item, i) => {
        const location = allLocations
          .filter((obj) => {
            let currentItem = item.location
              .split(",")
              .map((item) => parseInt(item));
            return currentItem.includes(obj.id);
          })
          .map((loc) => {
            return `${loc.location}`;
          })
          ?.toString();

        return {
          sno: i + 1,
          message: (
            <div>
              <Tooltip
                anchorSelect=".sc-message"
                place="top"
                // className="flex flex-col"
              >
                {item.message}
              </Tooltip>
              <div className="sc-message">{item.message}</div>
            </div>
          ),
          scheduled_time: moment(item.scheduled_time).format(
            "DD-MM-YY hh:mm A"
          ),
          location: (
            <>
              <Tooltip
                anchorSelect=".sc-location"
                place="top"
                // className="flex flex-col"
              >
                {location}
              </Tooltip>
              <div className="sc-location">{location}</div>
            </>
          ),
          status: (
            <div
              className={`${
                item.status === false || item.status === 0
                  ? "pill-btn-warning"
                  : "pill-btn-success"
              }`}
            >
              {item.status === false || item.status === 0
                ? "warning"
                : "delivered"}
            </div>
          ),
          // actions: (
          //   <div>
          //     <MdModeEditOutline
          //       className="text-2xl cursor-pointer"
          //       onClick={() => {
          //         setSelectedMessage(item);
          //         setUpdateMessageModal(true);
          //       }}
          //     />
          //   </div>
          // ),
        };
      })
    : [];
  // console.log({ allScheduledMessages });

  return (
    <div className="flex flex-col h-screen">
      <AddMessageModal
        isOpen={addMessageModal}
        setIsOpen={setAddMessageModal}
        allLocations={allLocations}
        currentUser={currentUser}
        refetchData={() => {
          getAllScheduledMessages();
        }}
      />
      <UpdateMessageModal
        isOpen={updateMessageModal}
        setIsOpen={setUpdateMessageModal}
        allLocations={allLocations}
        selectedMessage={selectedMessage}
        setSelectedMessage={setSelectedMessage}
        refetchData={() => {
          getAllScheduledMessages();
        }}
      />
      <Header />

      {loading ? (
        <div className="w-full flex flex-1 justify-center items-center">
          <SyncLoader color="#22c553" />
        </div>
      ) : (
        <div className="flex-1">
          <div className="flex justify-between items-center p-2">
            <Link
              to="/dashboard"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              {/* <span style={{ marginRight: "8px" }}> */}
              <IoArrowBack size={30} className="text-green-500" />
              {/* </span> */}
              {/* <span>Back</span> */}
            </Link>

            <button
              type="button"
              className="add-new-btn"
              onClick={() => setAddMessageModal(true)}
            >
              Add Scheduled Message
            </button>
          </div>
          <div className="w-11/12 mx-auto">
            <DataTable
              striped={true}
              title={"Scheduled Messages"}
              columns={tableColumns}
              data={tableData}
              highlightOnHover={true}
              // theme=""
              // pointerOnHover={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleMessage;
