import axios from "axios";

const serverURL =
  process.env.REACT_APP_SERVER_URL || "https://grwoth.techcarrel.in/api";
export const socketServerUrl =
  process.env.REACT_APP_SOCKET_SERVER_URL || "https://grwoth.techcarrel.in";

export const imageServerUrl =
  process.env.REACT_APP_IMAGE_URL || "https://grwoth.techcarrel.in";

// const serverURL = "https://grwoth.techcarrel.in/api";
// export const socketServerUrl = "https://grwoth.techcarrel.in";

// const serverURL = "http://localhost:4006/api";
// export const socketServerUrl = "http://localhost:4006";

const api = axios.create({
  baseURL: serverURL,
  // withCredentials: true,
});

// axiosRequest.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("authToken");
//     if (token) {
//       config.headers["Authorization"] = `Bearer ${token}`; // Set Authorization header if token exists
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export const post_api = async (url, data) => {
  return await api.post(url, data);
};

export const get_api = async (url) => {
  return await api.get(url);
};

export const post_api_formdata = async (url, data) => {
  return await api.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export default api;
