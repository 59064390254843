import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";
import { toast } from "react-toastify";
import { post_api } from "../../utils/axios-request";
import { SyncLoader } from "react-spinners";
import moment from "moment";

// const options = [
//   { label: "Grapes 🍇", value: "grapes" },
//   { label: "Mango 🥭", value: "mango" },
//   { label: "Strawberry 🍓", value: "strawberry", disabled: true },
// ];

const AddMessageModal = ({
  isOpen,
  setIsOpen,
  allLocations,
  currentUser,
  refetchData,
}) => {
  const [scheduledTime, setScheduledTime] = useState(new Date());
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    setScheduledTime(new Date());
    setSelectedLocation([]);
    setCurrentMessage("");
  };

  const handleMessage = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (!scheduledTime || !selectedLocation || currentMessage === "") {
        toast.error("All neccassary parameters required.");
        return;
      }

      const payload = {
        // scheduled_time: moment(scheduledTime).add(1, "hours").toDate(),
        scheduled_time: scheduledTime,
        message: currentMessage,
        location: selectedLocation.map((item) => item.value).toString(),
        created_by: currentUser?.id,
      };
      //   console.log({ payload });
      const response = await post_api(
        "/scheduled-message/create-new-scheduled-message",
        payload
      );
      if (response.data.status) {
        closeModal();
        toast.success(response.data.message);
        refetchData();
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return isToday(selectedDate) ? selectedDate > currentDate : true;
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Add Scheduled Message
                </Dialog.Title>
                {loading ? (
                  <div className="w-full flex justify-center items-center mt-5">
                    <SyncLoader color="#22c553" />
                  </div>
                ) : (
                  <div className="mt-5">
                    <div className="flex items-center justify-center">
                      {/* <!-- Author: FormBold Team --> */}
                      <div className="mx-auto w-full max-w-[550px] bg-white">
                        <form onSubmit={handleMessage}>
                          <div className="-mx-3 flex flex-wrap">
                            <div className="w-full px-3">
                              <div className="mb-5">
                                <label
                                  htmlFor="date"
                                  className="mb-3 block text-base font-medium text-[#07074D]"
                                >
                                  Scheduled Time
                                </label>
                                <DatePicker
                                  selected={scheduledTime}
                                  onChange={(date) => setScheduledTime(date)}
                                  minDate={new Date()}
                                  minTime={filterPassedTime(new Date())}
                                  filterTime={filterPassedTime}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={1}
                                  timeCaption="time"
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                  className="custom-date-wrapper w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6  text-black outline-none focus:border-green-500  focus:shadow-md"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="-mx-3 flex flex-wrap">
                            <div className="w-full px-3">
                              <div className="mb-5">
                                <label
                                  htmlFor="date"
                                  className="mb-3 block text-base font-medium text-[#07074D]"
                                >
                                  Location
                                </label>

                                <div className="relative text-black outline-none focus:border-green-500 focus:shadow-md">
                                  <MultiSelect
                                    className=""
                                    options={allLocations?.map((item) => ({
                                      label: item.location,
                                      value: item.id,
                                    }))}
                                    value={selectedLocation}
                                    onChange={setSelectedLocation}
                                    labelledBy="Select"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-5">
                            <label
                              htmlFor="name"
                              className="mb-3 block text-base font-medium text-[#07074D]"
                            >
                              Message
                            </label>
                            <textarea
                              value={currentMessage}
                              onChange={(e) =>
                                setCurrentMessage(e.target.value)
                              }
                              name="name"
                              id="name"
                              placeholder="Full Name"
                              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-black outline-none focus:border-green-500 focus:shadow-md"
                            />
                          </div>

                          <div>
                            <button type="submit" className="submit-btn">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddMessageModal;
