import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import ChangePasswordModal from "../Modal/ChangePasswordModal";
import { Link } from "react-router-dom";
import { get_api } from "../../utils/axios-request";

const UserProfile = () => {
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [repsName, setRepsName] = useState("");
  const [allTickets, setAllTickets] = useState("");

  const reps = JSON.parse(localStorage.getItem("data"));
  console.log("All", { allTickets });

  useEffect(() => {
    if (reps && reps.name) {
      const initials = getInitials(reps.name);
      setRepsName(initials);
    }
  }, []);

  const getInitials = (name) => {
    const nameParts = name.split(" ");
    if (nameParts.length >= 2) {
      const initials = nameParts[0][0] + nameParts[1][0];
      return initials.toUpperCase();
    }
    return name.charAt(0).toUpperCase();
  };

  const AllTickets = async () => {
    const response = await get_api(`/reps/get-rep-analytics-data/${reps.id}`);
    setAllTickets(response?.data);
  };

  useEffect(() => {
    AllTickets();
  }, []);

  return (
    <div className="">
      <ChangePasswordModal
        isOpen={changePasswordModal}
        setIsOpen={setChangePasswordModal}
      />
      <Header />
      <div className=" flex-1 p-8 mt-8">
        <div className="p-8 bg-white shadow h-[500px] flex items-center justify-around gap-40">
          <div className="grid grid-cols-1 md:grid-cols-3">
            <div className="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">
              <div>
                <p className="font-bold text-gray-700 text-xl">
                  {allTickets?.data?.active_tickets}
                </p>
                <p className="text-gray-400"> Active Tickets </p>
              </div>
              <div>
                <p className="font-bold text-gray-700 text-xl">
                  {" "}
                  {allTickets?.data?.escalated_tickets}
                </p>
                <p className="text-gray-400">Escalate Tickets</p>
              </div>
              <div>
                <p className="font-bold text-gray-700 text-xl">
                  {" "}
                  {allTickets?.data?.resolved_tickets}
                </p>
                <p className="text-gray-400">Resolve Tickets</p>
              </div>
            </div>
            <div className="relative mt-4">
              <div className="w-48 h-48 text-8xl font-bold bg-indigo-100 mx-auto rounded-full  absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
                {repsName}
              </div>
            </div>
            <div className="space-x-4 flex justify-between items-center w-96">
              <button
                onClick={() => setChangePasswordModal(true)}
                className="warning-btn flex-1 text-center"
              >
                Change Password
              </button>
              <Link to="/dashboard">
                <button className="submit-btn flex-1 text-center">
                  Tickets
                </button>
              </Link>
            </div>
            <div className="mt-8 col-span-12">
              <div className="mt-20 text-center border-b pb-12">
                <h1 className="text-4xl font-medium text-gray-700">
                  {reps.name.toUpperCase()}
                </h1>
                <p className="font-light text-gray-600 mt-3">{reps.email}</p>
                <p className="font-light text-gray-600 mt-3">{reps.mobile}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
