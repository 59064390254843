import React, { useEffect } from "react";
import img from "../../public/assets/images/growth-kitchen.png";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { post_api } from "../../utils/axios-request";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await post_api("/reps/rep-login", data);
      const token = response?.data?.data?.token;
      localStorage.setItem("token", token);
      localStorage.setItem(
        "data",
        JSON.stringify(response?.data?.data?.rep_data)
      );
      setLoading(false);
      console.log({ response: response.data });
      toast.success(response?.data?.data?.message);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      console.log({ errr: error.response.data });
      toast.error(error.response?.data?.message);
    }
  };

  return (
    <section>
      <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-8 lg:py-24">
        <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">
          <div className="mb-2 flex justify-center">
            <img src={img} alt="Example" className="h-24" />
          </div>
          <h2 className="text-center text-lg font-bold leading-tight text-black">
            Log in to Growth Kitchen to continue to analytics- web.
          </h2>

          <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-5">
              <div>
                <label
                  htmlFor="email"
                  className="text-base font-medium text-gray-900"
                >
                  Email address*
                </label>
                <div className="mt-2">
                  <input
                    {...register("email", { required: true })}
                    className={`flex h-10 w-full rounded-md border ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    } w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-black outline-none focus:border-green-500`}
                    type="email"
                    id="email"
                    placeholder="Email"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">
                      Email is required
                    </p>
                  )}
                </div>
              </div>
              <div className="relative">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="text-base font-medium text-gray-900"
                  >
                    Password*
                  </label>
                </div>
                <div className="mt-2">
                  <div className="relative flex items-center">
                    <input
                      {...register("password", { required: true })}
                      className={`flex h-10 w-full rounded-md border ${
                        errors.password ? "border-red-500" : "border-gray-300"
                      } bg-white py-2 px-6 text-black outline-none focus:border-green-500`}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="Password"
                    />
                    <span
                      className="absolute right-3 flex items-center cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    </span>
                  </div>
                  {errors.password && (
                    <p className="text-red-500 text-sm mt-1">
                      Password is required
                    </p>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="inline-flex w-full items-center justify-center rounded-md bg-[#00E27B] px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80 rounded-full"
                >
                  Get started{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="ml-2"
                  >
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
