import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";

import { SyncLoader } from "react-spinners";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { post_api } from "../../utils/axios-request";

const ChangePasswordModal = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [hideCurrentPassword, setHideCurrentPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideReEnterPassword, setHideReEnterPassword] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    setCurrentPassword("");
    setReEnterPassword("");
    setNewPassword("");
  };

  const togglePasswordVisibility = (field) => {
    if (field === "current") {
      setHideCurrentPassword(!hideCurrentPassword);
    } else if (field === "new") {
      setHideNewPassword(!hideNewPassword);
    } else if (field === "reEnter") {
      setHideReEnterPassword(!hideReEnterPassword);
    }
  };

  const handleSubmit = async (e) => {
    const userData = JSON.parse(localStorage.getItem("data"));
    e.preventDefault();
    if (!currentPassword || !newPassword || !reEnterPassword) {
      toast.error("All fields are required.");
      return;
    }
    if (newPassword !== reEnterPassword) {
      toast.error("New password and Re-entered password do not match.");
      return;
    }
    setLoading(true);
    try {
      const payload = {
        password: currentPassword,
        newPassword: newPassword,
      };
      const response = await post_api(
        `/reps/change-password-by-self/${userData.id}`,
        payload
      );
      if (response.data.status) {
        toast.success("Password changed successfully.");
        closeModal();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 flex items-center justify-between"
                >
                  Change Password
                  <button onClick={closeModal}>
                    <IoCloseSharp size={20} />
                  </button>
                </Dialog.Title>
                {loading ? (
                  <div className="w-full flex justify-center items-center mt-5">
                    <SyncLoader color="#22c553" />
                  </div>
                ) : (
                  <div className="mt-5">
                    <div className="flex items-center justify-center">
                      <div className="mx-auto w-full max-w-[550px] bg-white">
                        <form onSubmit={handleSubmit}>
                          <div className="-mx-3 flex flex-wrap">
                            <div className="w-full px-3">
                              <div className="mb-2 relative">
                                <label
                                  htmlFor="current-password"
                                  className="text-base font-medium text-gray-900"
                                >
                                  Current Password
                                </label>
                                <div className="mt-2 flex items-center">
                                  <input
                                    id="current-password"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-black outline-none focus:border-green-500"
                                    type={
                                      hideCurrentPassword ? "password" : "text"
                                    }
                                    placeholder="Current Password"
                                    value={currentPassword}
                                    onChange={(e) =>
                                      setCurrentPassword(e.target.value)
                                    }
                                  />
                                  <button
                                    type="button"
                                    className="absolute right-3"
                                    onClick={() =>
                                      togglePasswordVisibility("current")
                                    }
                                  >
                                    {hideCurrentPassword ? (
                                      <IoEyeOffOutline size={20} />
                                    ) : (
                                      <IoEyeOutline size={20} />
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="-mx-3 flex flex-wrap">
                            <div className="w-full px-3">
                              <div className="mb-2 relative">
                                <label
                                  htmlFor="new-password"
                                  className="text-base font-medium text-gray-900"
                                >
                                  New Password
                                </label>
                                <div className="mt-2 flex items-center">
                                  <input
                                    id="new-password"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-black outline-none focus:border-green-500"
                                    type={hideNewPassword ? "password" : "text"}
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                  />
                                  <button
                                    type="button"
                                    className="absolute right-3"
                                    onClick={() =>
                                      togglePasswordVisibility("new")
                                    }
                                  >
                                    {hideNewPassword ? (
                                      <IoEyeOffOutline size={20} />
                                    ) : (
                                      <IoEyeOutline size={20} />
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-5 relative">
                            <div className="flex items-center justify-between">
                              <label
                                htmlFor="re-enter-password"
                                className="text-base font-medium text-gray-900"
                              >
                                Re-Enter Password
                              </label>
                            </div>
                            <div className="mt-2 flex items-center">
                              <input
                                id="re-enter-password"
                                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6 text-black outline-none focus:border-green-500"
                                type={hideReEnterPassword ? "password" : "text"}
                                placeholder="Re-Enter Password"
                                value={reEnterPassword}
                                onChange={(e) =>
                                  setReEnterPassword(e.target.value)
                                }
                              />
                              <button
                                type="button"
                                className="absolute right-3"
                                onClick={() =>
                                  togglePasswordVisibility("reEnter")
                                }
                              >
                                {hideReEnterPassword ? (
                                  <IoEyeOffOutline size={20} />
                                ) : (
                                  <IoEyeOutline size={20} />
                                )}
                              </button>
                            </div>
                          </div>

                          <div>
                            <button type="submit" className="submit-btn">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ChangePasswordModal;
