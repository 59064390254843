import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../public/assets/images/growth-kitchen.png";
import { Menu, Transition } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";

const Header = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(() =>
    JSON.parse(localStorage.getItem("data"))
  );

  const handleLogout = () => {
    const token = localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <header className="text-gray-600 body-font shadow-md z-10">
      <div className="flex justify-between p-6 items-center w-full px-6 py-2 ">
        <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            className="w-10 h-10 text-white p-2 bg-green-500 rounded-full"
            viewBox="0 0 24 24"
          >
            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
          </svg> */}
          <img src={Logo} alt="Example" className="h-10" />
          {/* <span className="ml-3 text-xl">Tailblocks</span> */}
        </a>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
          {/* <Link className="mr-5 hover:text-gray-900" to={"/dashboard"}>
            Dashboard
          </Link>
          <Link className="mr-5 hover:text-gray-900" to={"/user-profile"}>
            Profile
          </Link> */}

          <div>
            <Menu as="div" className="relative inline-block text-left">
              <div className="relative">
                <Menu.Button className="relative flex items-center gap-4 pr-6">
                  <img className="w-10 h-10 rounded-full" src={Logo} alt="" />
                  <div className="font-medium">
                    <div>{userData?.name}</div>
                  </div>
                  <FaChevronDown
                    className="absolute right-1 top-4 h-3 w-3 cursor-pointer"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? "bg-green-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          to={"/dashboard"}
                        >
                          Tickets
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? "bg-green-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          to={"/schedule-message"}
                        >
                          Schedule Message
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? "bg-green-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          to={"/user-profile"}
                        >
                          Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-green-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={handleLogout}
                        >
                          Logout
                          <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            className="w-4 h-4 ml-1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                          </svg>
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </nav>
        {/* <button
          onClick={handleLogout}
          className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0"
        >
          Logout
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            className="w-4 h-4 ml-1"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </button> */}
      </div>
    </header>
  );
};

export default Header;
