import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoIosNotifications } from "react-icons/io";
import io from "socket.io-client";
import SingleMsg from "../chat/SingleMsg";
import Header from "../Header/Header";
import { FaChevronDown } from "react-icons/fa6";
import { toast } from "react-toastify";
import Logo from "../../public/assets/images/growth-kitchen.png";
import { SyncLoader } from "react-spinners";
import moment from "moment";
import {
  get_api,
  post_api,
  post_api_formdata,
  socketServerUrl,
} from "../../utils/axios-request";
import { Tooltip } from "react-tooltip";
import EscalateTicketModal from "../Modal/EscalateTicketModal";
import nochat from "../../public/assets/images/nochats.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import { MdOutlineAttachment } from "react-icons/md";

// const ENDPOINT = "https://grwoth.techcarrel.in";
// const ENDPOINT = "http://173.212.241.37:4006";
// const socket = socketIOClient(ENDPOINT, { path: "/api/socket" });

const Dashboard = () => {
  const fileInputRef = useRef(null);
  const socket = useMemo(() => io(socketServerUrl), []);
  const [searchParams] = useSearchParams();
  const paramTicketId = searchParams.get("ticketId");

  const [data, setData] = useState(() =>
    JSON.parse(localStorage.getItem("data"))
  );
  const [ticketType, setTicketType] = useState(() => {
    let data = JSON.parse(localStorage.getItem("data"));
    if (data?.role === "manager") {
      return "escalated";
    } else {
      return "active";
    }
  });
  const [allTickets, setAllTickets] = useState([]);
  const [userData, setUserData] = useState(null);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [activeTicketLoader, setActiveTicketLoader] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [allManagers, setAllManagers] = useState([]);

  const [onlineUsers, setOnlineUsers] = useState([]);

  const [escalateTicketModal, setEscalateTicketModal] = useState(false);

  const [AllGroupedMessages, setAllGroupedMessages] = useState([]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  const currentTicketRef = useRef(currentTicket);

  // useEffect(() => {
  //   currentTicketRef.current = currentTicket;
  // }, [currentTicket]);

  // console.log("activeTicketLoader", allTickets);

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  useEffect(() => {
    currentTicketRef.current = currentTicket;
    if (currentTicket) {
      dateWiseMessage();
    }
  }, [currentTicket]);

  const dateWiseMessage = async () => {
    const response = await get_api(
      `/chat-message/get-grouped-messages-by-chat-id/${currentTicket.chat_id}`
    );
    setAllGroupedMessages([]);

    // console.log("Date", response.data);

    if (response.data.status) {
      setAllGroupedMessages(
        response.data.data.map((item) => {
          let all_messages =
            typeof item.messages === "string"
              ? JSON.parse(item.messages)
              : item.messages;

          let sortedMessages = all_messages.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );

          // console.log("Sorted", sortedMessages);

          return {
            ...item,
            messages: sortedMessages,
          };
        })
      );
    }
  };

  // console.log(AllGroupedMessages);

  useEffect(() => {
    // console.log({ currentTicket });
    socket.on("connect", () => {
      console.log("Connected to server");
    });

    const storedData = JSON.parse(localStorage.getItem("data"));
    if (storedData) {
      socket.emit("new-user-add", storedData?.id);
    }
    socket.on("get-users", (users) => {
      setOnlineUsers(users);
      // console.log({ users });
    });
    // }
    socket.on("receive-message", (msg) => {
      if (
        parseInt(currentTicketRef.current?.chat_id) === parseInt(msg.chat_id)
      ) {
        setAllMessages((prevMessages) => [...prevMessages, msg]);
        // Update the state with the new message
        setAllGroupedMessages((prevData) => {
          // Make a copy of the current data
          const newData = [...prevData];
          newData[newData.length - 1].messages.push({
            ...msg,
            createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
          return newData;
        });
        scrollToBottom();
      }
    });

    socket.on("disconnect", (reason) => {
      console.log("Disconnected from server:", reason);
      if (reason === "io server disconnect") {
        // The disconnection was initiated by the server, need to manually reconnect
        socket.connect();
        socket.emit("new-user-add", storedData?.id);
      }
    });

    socket.on("connect_error", (error) => {
      console.error("Connection error:", error);
    });

    return () => {
      socket.off("connect");
      socket.off("message");
      socket.off("disconnect");
      socket.off("connect_error");
    };
  }, [socket]);

  const getTicketsRep = async (prevTickets = []) => {
    setActiveTicketLoader(true);
    const response = await get_api(`/ticket/get-tickets-by-rep-id/${data?.id}`);
    if (response.data.status) {
      setAllTickets(response.data?.data);
      setActiveTicketLoader(false);
    }
    setActiveTicketLoader(false);
  };

  useEffect(() => {
    if (data) {
      getTicketsRep();
    }
  }, [data]);

  useEffect(() => {
    socket.on("receive-all-tickets", (data) => {
      console.log({ receivedTicket: data });
      setAllTickets((prev) => [data, ...prev]);
      setNotifications((prev) => [data, ...prev]);
      // getTicketsRep();
    });
  }, [socket]);

  useEffect(() => {
    socket.on("recieve-escalated-ticket", (data) => {
      console.log({ receivedEscalatedTicket: data });
      getTicketsRep();
    });
  }, []);

  const navigate = useNavigate();
  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const getMessages = async (ticket_id) => {
    try {
      setLoading(true);
      // const response = await axios.get(
      //   `https://grwoth.techcarrel.in/api/chat-message/get-messages-by-chat-id/${
      //     ticket_id ? ticket_id : 0
      //   }`
      // );

      const response = await get_api(
        `/chat-message/get-messages-by-chat-id/${ticket_id ? ticket_id : 0}`
      );
      // console.log({ data: response.data });
      if (response.data) {
        setAllMessages(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };

  const getAllManagers = async (location_id) => {
    try {
      const response = await get_api(
        `/reps/get-all-managers-by-location/${location_id ? location_id : 0}`
      );
      if (response.data.status) {
        setAllManagers(response.data.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    if (currentTicket) {
      getMessages(currentTicket.chat_id);
      getAllManagers(currentTicket.location);
    }
  }, [currentTicket]);

  // Socket Setup
  const [message, setMessage] = useState("");

  const sendMessage = (e) => {
    e.preventDefault();
    if (message.trim() !== "") {
      let msgData = {
        user_id: data?.id,
        message,
        attachment: "",
        contact_number: userData?.whatsapp_number,
        chat_id: currentTicket?.chat_id,
        wa_chat_id: currentTicket?.wa_chat_id,
        // createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      socket.emit("send-message", msgData);
      scrollToBottom();
      setAllMessages((prevMessages) => [
        ...prevMessages,
        {
          ...msgData,
          sender_id: data?.id,
          createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      ]);

      // Update the state with the new message
      setAllGroupedMessages((prevData) => {
        // Make a copy of the current data
        const newData = [...prevData];
        newData[newData.length - 1].messages.push({
          ...msgData,
          sender_id: data?.id,
          createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
        return newData;
      });

      // data[0].messages.push(newMessage);
      setMessage("");
    }
  };

  // const sendMessage = (e) => {
  //   e.preventDefault();
  //   if (message.trim() !== "") {
  //     // Create message data
  //     let msgData = {
  //       user_id: data?.id,
  //       message,
  //       attachment: "",
  //       contact_number: userData?.whatsapp_number,
  //       chat_id: currentTicket?.chat_id,
  //       wa_chat_id: currentTicket?.wa_chat_id,
  //       createdAt: moment().toISOString(), // Using ISO format for universal time
  //     };

  //     // Emit the message through WebSocket
  //     socket.emit("send-message", msgData, (error) => {
  //       if (error) {
  //         console.error("Message send failed:", error);
  //         // Optionally show error feedback to the user
  //         return;
  //       }

  //       // If no error, update local state
  //       scrollToBottom();
  //       setAllMessages((prevMessages) => [
  //         ...prevMessages,
  //         {
  //           ...msgData,
  //           sender_id: data?.id,
  //           // createdAt: moment().toISOString(),
  //           updatedAt: moment().toISOString(),
  //         },
  //       ]);

  //       setAllGroupedMessages((prevData) => {
  //         const newData = [...prevData];
  //         newData[newData.length - 1].messages.push({
  //           ...msgData,
  //           sender_id: data?.id,
  //           // createdAt: moment().toISOString(),
  //           updatedAt: moment().toISOString(),
  //         });
  //         return newData;
  //       });

  //       // Clear the message input
  //       setMessage("");
  //     });
  //   }
  // };

  const handleTicketType = (type) => {
    setTicketType(type);
    setCurrentTicket(null);
    setUserData(null);
  };

  const handleTicketResolved = async () => {
    try {
      setLoading(true);
      const response = await post_api(
        `/ticket/update-ticket-status/${currentTicket.id}`,
        { status: "resolved" }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        getTicketsRep();
        setUserData(null);
        setCurrentTicket(null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
      console.log({ error });
    }
  };

  // const handleTicketEscalation = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await post_api(
  //       `/ticket/update-ticket-status/${currentTicket.id}`,
  //       { status: "escalated" }
  //     );
  //     if (response.data.status) {
  //       toast.success(response.data.message);
  //       getTicketsRep();
  //       setUserData(null);
  //       setCurrentTicket(null);
  //       socket.emit("escalated-ticket", { id: parseInt(data.parent_reps) });
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error(error.response.data.message);
  //     console.log({ error });
  //   }
  // };

  useEffect(() => {
    if (paramTicketId && allTickets) {
      const paramTicket = allTickets.find(
        (item) => item.id === parseInt(paramTicketId)
      );

      if (paramTicket) {
        if (data.role === "customer_reps" && paramTicket.status === "active") {
          setCurrentTicket(paramTicket);
          setUserData(paramTicket.customer_data);
        } else if (
          data.role === "manager" &&
          paramTicket.status === "escalated"
        ) {
          setCurrentTicket(paramTicket);
          setUserData(paramTicket.customer_data);
        } else {
          setCurrentTicket(null);
          setUserData(null);
        }
      } else {
        setCurrentTicket(null);
        setUserData(null);
      }
    }
  }, [allTickets, data, paramTicketId]);

  const formatDate = (date) => {
    const messageDate = moment(date);
    const today = moment();
    const yesterday = moment().subtract(1, "days");

    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(yesterday, "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("DD/MM/YY");
    }
  };

  const hasNotifications = notifications.length > 0;

  const handleFileChange = async (event) => {
    try {
      const files = Array.from(event.target.files);
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];

      const validFiles = files.filter((file) =>
        validImageTypes.includes(file.type)
      );
      if (validFiles.length !== files.length) {
        toast.error("Some files are not valid images.");
        return;
      }

      const formData = new FormData();
      validFiles.forEach((file) => {
        formData.append("image", file);
      });

      const response = await post_api_formdata(
        `/chat-message/create-chat-attachment`,
        formData
      );
      if (response.data.status) {
        const all_data = response.data.data;

        for (let index = 0; index < all_data.length; index++) {
          const element = all_data[index];
          let msgData = {
            user_id: data?.id,
            message: "",
            attachment: element,
            contact_number: userData?.whatsapp_number,
            chat_id: currentTicket?.chat_id,
            wa_chat_id: currentTicket?.wa_chat_id,
            // createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            // createdAt: moment().toISOString(),
          };
          socket.emit("send-message", msgData);

          // Update the state with the new message
          setAllGroupedMessages((prevData) => {
            // Make a copy of the current data
            const newData = [...prevData];
            newData[newData.length - 1].messages.push({
              ...msgData,
              sender_id: data?.id,
              createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
              updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            });
            return newData;
          });
        }
        // console.log({ response: response.data.data });

        scrollToBottom();
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <EscalateTicketModal
        isOpen={escalateTicketModal}
        setIsOpen={setEscalateTicketModal}
        currentTicket={currentTicket}
        allManagers={allManagers}
        refetchData={() => {
          socket.emit("escalated-ticket", {
            id: parseInt(data.parent_reps),
            ticketId: currentTicket?.id,
          });
          getTicketsRep();
          setUserData(null);
          setCurrentTicket(null);
        }}
      />
      <Header />
      <div className="flex flex-1 antialiased text-gray-800">
        <div className="flex flex-row w-full overflow-x-hidden">
          <div className="flex flex-col py-3 px-6 w-2/12 bg-white flex-shrink-0 border-r-2 border-gray-400">
            <div className="flex justify-between items-center mb-5">
              <div>
                <Menu as="div" className="relative inline-block text-left">
                  <div className="relative">
                    <Menu.Button className="text-left text-blue-700 border border-blue-700 font-medium rounded-lg text-sm pl-2 pr-10 py-2">
                      {/* Select Ticket Type */}
                      {ticketType === "active" || ticketType === "escalated"
                        ? "Active Ticket"
                        : "Resolved Ticket"}
                      <FaChevronDown
                        className="absolute right-1 top-3 h-3 w-3 text-blue-700 hover:text-blue-800 cursor-pointer"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        {data?.role !== "manager" && (
                          <Menu.Item className="mb-1">
                            {({ active }) => (
                              <button
                                className={`${
                                  ticketType === "active"
                                    ? "bg-green-500 text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                                onClick={() => handleTicketType("active")}
                              >
                                Active
                              </button>
                            )}
                          </Menu.Item>
                        )}

                        {data?.role === "manager" && (
                          <Menu.Item className="mb-1">
                            {({ active }) => (
                              <button
                                className={`${
                                  ticketType === "escalated"
                                    ? "bg-green-500 text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                                onClick={() => handleTicketType("escalated")}
                              >
                                Active
                              </button>
                            )}
                          </Menu.Item>
                        )}

                        <Menu.Item className="">
                          {({ active }) => (
                            <button
                              className={`${
                                ticketType === "resolved"
                                  ? "bg-green-500 text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                              onClick={() => handleTicketType("resolved")}
                            >
                              Resolved
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              {notifications && (
                <Tooltip
                  anchorSelect=".notify"
                  place="bottom"
                  className="flex flex-col"
                >
                  {notifications?.map((item) => {
                    return (
                      <span>{`${item.customer_data.name}-${moment(
                        item.createdAt
                      ).format("DD/MM/YY hh:mm A")}`}</span>
                    );
                  })}
                </Tooltip>
              )}
              {ticketType !== "resolved" && (
                <div
                  className={`relative cursor-pointer ${
                    hasNotifications ? "notify" : ""
                  }`}
                  // onClick={() => getTicketsRep()}
                >
                  <IoIosNotifications size={30} color="#22C55E" />
                  <div
                    className={`absolute flex items-center justify-center text-white h-4 w-4 rounded-full top-0 right-0 ${
                      hasNotifications ? "bg-red-600" : ""
                    }`}
                  >
                    <span className="text-sm">
                      {hasNotifications ? notifications.length : ""}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <hr className="w-full " />
            <div className="flex flex-col mt-5">
              <div className="flex flex-row items-center justify-between text-xs">
                <span className="font-bold">
                  {ticketType === "active"
                    ? "Active Conversations"
                    : ticketType === "resolved"
                    ? "Resolved Conversations"
                    : "Active Conversations"}
                </span>
                <div className="flex items-center justify-center bg-green-500 text-white h-6 w-6 rounded-full">
                  <span className="text-sm">
                    {
                      allTickets.filter((item) => item.status === ticketType)
                        .length
                    }
                  </span>
                </div>
              </div>
              <div
                className="flex flex-col space-y-1 mt-4 cursor-pointer overflow-auto pr-2"
                style={{ maxHeight: "490px" }}
              >
                {activeTicketLoader ? (
                  <>
                    {/* <h1>hello</h1> */}
                    <>
                      <div>
                        {[...Array(6)].map((_, index) => (
                          <div
                            key={index}
                            className="flex items-center space-x-2 mb-1 border border-gray-200 p-2 rounded-xl"
                          >
                            <div className="flex-shrink-0">
                              <Skeleton circle width={40} height={40} />
                            </div>
                            <div className="flex-1">
                              <Skeleton width="90%" />
                              {/* <Skeleton width="60%" /> */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  </>
                ) : (
                  allTickets
                    ?.filter((item) => item.status === ticketType)
                    .map((ticket, i) => (
                      <div
                        key={i}
                        className={`bg-white rounded-xl border border-gray-400 hover:border-l-4 hover:border-green-500 ${
                          currentTicket?.id === ticket.id
                            ? "border-l-4 border-green-500"
                            : ""
                        }`}
                      >
                        <div
                          className="flex flex-row items-center rounded-xl p-2"
                          onClick={() => {
                            setUserData(ticket.customer_data);
                            setCurrentTicket(ticket);
                          }}
                        >
                          <div className="flex items-center justify-center h-10 w-10 bg-indigo-200 rounded-full">
                            {ticket?.customer_data?.name
                              .split(" ")
                              .map((part) => part.charAt(0))
                              .join("")}
                          </div>
                          <div className="flex flex-col pl-2">
                            <button className="text-black font-semibold mb-1 leading-none">
                              {ticket?.customer_data?.name}
                            </button>
                            <span className="text-xs">
                              {moment(ticket.createdAt).format(
                                "DD/MM/YY hh:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          </div>
          {!loading && currentTicket ? (
            <div
              className="flex flex-col flex-auto px-6 py-3"
              // style={{ maxHeight: "96%", overflowY: "hidden" }}
            >
              <div className="flex justify-between items-center">
                <div className="flex flex-row items-center rounded-xl p-2">
                  <div className="flex items-center justify-center h-10 w-10 bg-indigo-200 rounded-full">
                    {userData?.name
                      .split(" ")
                      .map((part) => part.charAt(0))
                      .join("")}{" "}
                  </div>
                  <div className="ml-2 flex flex-col flex-grow ">
                    <p className="text-black font-semibold mb-1 leading-none">
                      {userData?.name}
                    </p>
                    <div className="text-gray-500 leading-none flex items-center gap-x-3 text-xs">
                      <span>
                        Created:{" "}
                        {moment(currentTicket.createdAt).format(
                          "DD/MM/YY hh:mm A"
                        )}
                      </span>
                      {currentTicket?.escalated_time && (
                        <>
                          <span>|</span>
                          <span>
                            Escalated:
                            {moment(currentTicket.escalated_time).format(
                              "DD/MM/YY hh:mm A"
                            )}
                          </span>
                        </>
                      )}

                      {currentTicket?.resolved_time && (
                        <>
                          <span>|</span>
                          <span>
                            Resolved:{" "}
                            {moment(currentTicket.resolved_time).format(
                              "DD/MM/YY hh:mm A"
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {currentTicket && (
                  <div>
                    <Menu as="div" className="relative inline-block text-left">
                      <div className="relative">
                        <Menu.Button>
                          <BsThreeDotsVertical
                            size={30}
                            className="text-blue-700 hover:text-blue-800 cursor-pointer"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                          <div className="px-1 py-1 ">
                            {currentTicket?.status !== "resolved" && (
                              <>
                                <Menu.Item className="mb-1">
                                  {({ active }) => (
                                    <button
                                      type="button"
                                      className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                                      disabled={
                                        data.id ===
                                          parseInt(currentTicket.agent_id) &&
                                        currentTicket.status === "escalated"
                                          ? true
                                          : false
                                      }
                                      onClick={handleTicketResolved}
                                    >
                                      Resolve
                                    </button>
                                  )}
                                </Menu.Item>
                                {currentTicket?.status !== "escalated" && (
                                  <Menu.Item className="mb-1">
                                    {({ active }) => (
                                      <button
                                        type="button"
                                        className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                                        disabled={
                                          data.id ===
                                            parseInt(currentTicket.agent_id) &&
                                          currentTicket.status === "escalated"
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          setEscalateTicketModal(true);
                                        }}
                                      >
                                        Escalate
                                      </button>
                                    )}
                                  </Menu.Item>
                                )}
                              </>
                            )}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    {/* {currentTicket?.status !== "resolved" && (
                      <>
                        <button
                          type="button"
                          className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-3 py-1 text-center me-2 mb-2"
                          disabled={
                            data.id === parseInt(currentTicket.agent_id) &&
                            currentTicket.status === "escalated"
                              ? true
                              : false
                          }
                          onClick={handleTicketResolved}
                        >
                          Resolve
                        </button>

                        {currentTicket?.status !== "escalated" && (
                          <button
                            type="button"
                            className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-3 py-1 text-center me-2 mb-2"
                            disabled={
                              data.id === parseInt(currentTicket.agent_id) &&
                              currentTicket.status === "escalated"
                                ? true
                                : false
                            }
                            onClick={() => {
                              setEscalateTicketModal(true);
                            }}
                          >
                            Escalate
                          </button>
                        )}
                      </>
                    )} */}
                  </div>
                )}
              </div>

              <hr className="w-full " />

              <div className="flex flex-col flex-auto rounded-2xl bg-gray-100 h-[300px] p-4 mt-4 justify-between">
                <div className="flex flex-col overflow-x-auto mb-4">
                  <div className="flex flex-col">
                    <div className="grid grid-cols-12 gap-y-2">
                      {/* {console.log({ AllGroupedMessages })} */}
                      {AllGroupedMessages.length > 0 ? (
                        AllGroupedMessages.map((group, i) => (
                          <div key={i} className="col-span-12">
                            <div className="text-center text-gray-500 my-2">
                              {formatDate(group.date)}
                              {/* {moment(group.date).isSame(moment(), 'day') ? 'Today' : moment(group.date).is} */}
                            </div>
                            {group.messages.map((item, j) => (
                              <SingleMsg
                                key={j}
                                userData={userData}
                                item={item}
                                data={data}
                                index={j}
                              />
                            ))}
                          </div>
                        ))
                      ) : (
                        <div className="col-span-12 m-auto">
                          <img
                            src={nochat}
                            className="h-96 w-96"
                            alt="No Graphity"
                          />
                        </div>
                      )}
                      <div ref={messagesEndRef} />
                    </div>
                  </div>
                </div>

                {ticketType !== "resolved" && (
                  <form
                    onSubmit={sendMessage}
                    className="flex flex-row items-start rounded-xl bg-white w-full p-4 gap-x-3"
                  >
                    <div className="flex-1">
                      <div className="relative w-full">
                        <input
                          placeholder="Enter your message here..."
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                          disabled={
                            (data?.id === parseInt(currentTicket?.agent_id) &&
                              currentTicket?.status === "escalated") ||
                            currentTicket?.status === "resolved"
                              ? true
                              : false
                          }
                        />
                        <MdOutlineAttachment
                          className="absolute right-3 top-1.5 cursor-pointer"
                          size={28}
                          onClick={() => fileInputRef.current.click()}
                        />
                        <input
                          type="file"
                          ref={fileInputRef}
                          className="hidden"
                          accept="image/*"
                          multiple
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="ml-2 text-center text-xs text-gray-400 italic w-full">
                        Note: A ticket must be resolved within 24hrs of its
                        initialization.
                      </div>
                    </div>
                    <div className="">
                      <button className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl px-4 h-10 text-white">
                        Send
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          ) : loading ? (
            <div className="w-full flex flex-col justify-center items-center">
              <SyncLoader color="#22c553" />
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center">
              <img src={nochat} alt="Example" className="h-96 w-96" />
              <h1 className="text-xl">No Chats Available</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
